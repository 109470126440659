const typeData = [
    // 主分类
    {
        "type_id": 1,
        "type_pid": 0,
        "type_name": "电影"
    },
    {
        "type_id": 2,
        "type_pid": 0,
        "type_name": "连续剧"
    },
    {
        "type_id": 3,
        "type_pid": 0,
        "type_name": "综艺"
    },
    {
        "type_id": 4,
        "type_pid": 0,
        "type_name": "动漫"
    },
    {
        "type_id": 40,
        "type_pid": 0,
        "type_name": "体育"
    },
    // 电影子分类
    {
        "type_id": 41,
        "type_pid": 1,
        "type_name": "动作片"
    },
    {
        "type_id": 42,
        "type_pid": 1,
        "type_name": "喜剧片"
    },
    {
        "type_id": 43,
        "type_pid": 1,
        "type_name": "爱情片"
    },
    {
        "type_id": 44,
        "type_pid": 1,
        "type_name": "科幻片"
    },
    {
        "type_id": 45,
        "type_pid": 1,
        "type_name": "恐怖片"
    },
    {
        "type_id": 46,
        "type_pid": 1,
        "type_name": "剧情片"
    },
    {
        "type_id": 47,
        "type_pid": 1,
        "type_name": "战争片"
    },
    {
        "type_id": 48,
        "type_pid": 1,
        "type_name": "惊悚片"
    },
    {
        "type_id": 49,
        "type_pid": 1,
        "type_name": "家庭片"
    },
    {
        "type_id": 50,
        "type_pid": 1,
        "type_name": "古装片"
    },
    {
        "type_id": 51,
        "type_pid": 1,
        "type_name": "历史片"
    },
    {
        "type_id": 52,
        "type_pid": 1,
        "type_name": "悬疑片"
    },
    {
        "type_id": 53,
        "type_pid": 1,
        "type_name": "犯罪片"
    },
    {
        "type_id": 54,
        "type_pid": 1,
        "type_name": "灾难片"
    },
    {
        "type_id": 55,
        "type_pid": 1,
        "type_name": "记录片"
    },
    {
        "type_id": 56,
        "type_pid": 1,
        "type_name": "短片"
    },
    {
        "type_id": 57,
        "type_pid": 1,
        "type_name": "动画片"
    },
    {
        "type_id": 89,
        "type_pid": 1,
        "type_name": "伦理片"
    },
    // 连续剧子分类
    {
        "type_id": 58,
        "type_pid": 2,
        "type_name": "国产剧"
    },
    {
        "type_id": 59,
        "type_pid": 2,
        "type_name": "香港剧"
    },
    {
        "type_id": 60,
        "type_pid": 2,
        "type_name": "韩国剧"
    },
    {
        "type_id": 61,
        "type_pid": 2,
        "type_name": "欧美剧"
    },
    {
        "type_id": 62,
        "type_pid": 2,
        "type_name": "台湾剧"
    },
    {
        "type_id": 63,
        "type_pid": 2,
        "type_name": "日本剧"
    },
    {
        "type_id": 64,
        "type_pid": 2,
        "type_name": "海外剧"
    },
    {
        "type_id": 65,
        "type_pid": 2,
        "type_name": "泰国剧"
    },
    {
        "type_id": 66,
        "type_pid": 2,
        "type_name": "爽文短剧"
    },
    {
        "type_id": 86,
        "type_pid": 2,
        "type_name": "电影解说"
    },
    // 综艺子分类
    {
        "type_id": 67,
        "type_pid": 3,
        "type_name": "大陆综艺"
    },
    {
        "type_id": 68,
        "type_pid": 3,
        "type_name": "港台综艺"
    },
    {
        "type_id": 69,
        "type_pid": 3,
        "type_name": "日韩综艺"
    },
    {
        "type_id": 70,
        "type_pid": 3,
        "type_name": "欧美综艺"
    },
    {
        "type_id": 90,
        "type_pid": 3,
        "type_name": "其他综艺"
    },
    // 动漫子分类
    {
        "type_id": 71,
        "type_pid": 4,
        "type_name": "国产动漫"
    },
    {
        "type_id": 72,
        "type_pid": 4,
        "type_name": "欧美动漫"
    },
    {
        "type_id": 73,
        "type_pid": 4,
        "type_name": "日本动漫"
    },
    {
        "type_id": 74,
        "type_pid": 4,
        "type_name": "里番动漫"
    },
    // 体育子分类
    {
        "type_id": 75,
        "type_pid": 40,
        "type_name": "足球"
    },
    {
        "type_id": 76,
        "type_pid": 40,
        "type_name": "德甲"
    },
    {
        "type_id": 77,
        "type_pid": 40,
        "type_name": "意甲"
    },
    {
        "type_id": 78,
        "type_pid": 40,
        "type_name": "英超"
    },
    {
        "type_id": 79,
        "type_pid": 40,
        "type_name": "西甲"
    },
    {
        "type_id": 80,
        "type_pid": 40,
        "type_name": "法甲"
    },
    {
        "type_id": 81,
        "type_pid": 40,
        "type_name": "NBA"
    },
    {
        "type_id": 82,
        "type_pid": 40,
        "type_name": "CBA"
    },
    {
        "type_id": 83,
        "type_pid": 40,
        "type_name": "LPL"
    },
    {
        "type_id": 84,
        "type_pid": 40,
        "type_name": "WCBA"
    },
    {
        "type_id": 85,
        "type_pid": 40,
        "type_name": "篮球"
    },
    {
        "type_id": 87,
        "type_pid": 40,
        "type_name": "网球"
    },
    {
        "type_id": 88,
        "type_pid": 40,
        "type_name": "斯诺克"
    }
];

const processTypeList = (typeData) => {
    const mainTypes = typeData.filter(type => type.type_pid === 0);
    
    return mainTypes.map(mainType => ({
        id: mainType.type_id.toString(),
        name: mainType.type_name,
        subTypes: typeData
            .filter(type => type.type_pid === mainType.type_id)
            .map(subType => ({
                id: subType.type_id.toString(),
                name: subType.type_name
            }))
    }));
};

const typeList = processTypeList(typeData);
export {
    typeList,
    typeData
};