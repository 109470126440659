import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import axios from 'axios';
// import VideoJS from '../video';
import Hls from 'hls.js';
import DPlayer from 'dplayer';
import { API_ENDPOINT } from '../config/api';
// import VConsole from 'vconsole';
import { Helmet } from 'react-helmet';
function Detail() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [movie, setMovie] = useState(location.state?.movie || null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [currentEpisodeUrl, setCurrentEpisodeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const currentEpisodeUrlRef = useRef('');
  const playerContainerRef = useRef(null);
  const dPlayerRef = useRef(null);
  const [currentSource, setCurrentSource] = useState(0);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [sourcePings, setSourcePings] = useState({});
  // 添加延迟检测函数
  const checkSourceLatency = useCallback(async (url) => {
    try {
      const startTime = performance.now();
      await fetch(url, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store'
      });
      const endTime = performance.now();
      return Math.round(endTime - startTime);
    } catch (error) {
      return -1; // 表示连接失败
    }
  }, []);
  // 添加批量检测函数
  const checkAllSourcesLatency = useCallback(async (sources) => {
    const pingPromises = sources.map(async (source) => {
      if (!source.episodes.length) return;
      const url = new URL(source.episodes[0].url);
      const latency = await checkSourceLatency(`${url.protocol}//${url.hostname}`);
      setSourcePings(prev => ({
        ...prev,
        [source.name]: latency
      }));
    });
    // 异步执行所有检测
    Promise.all(pingPromises);
  }, [checkSourceLatency]);



  // 修改播放源处理逻辑
  const getPlaySources = useCallback((movie) => {
    if (!movie?.vod_play_url) return [];
    return movie.vod_play_url.split(',').reverse().map(source => {
      const [name, urls] = source.split('@');
      return {
        name,
        episodes: urls ? urls.split('#').map(item => {
          const [epName, url] = item.split('$');
          return { epName, url };
        }).filter(item => item.url) : []
      };
    });
  }, []);

  // 在 movie 数据加载后触发检测
  useEffect(() => {
    if (movie?.vod_play_url) {
      const sources = getPlaySources(movie);
      checkAllSourcesLatency(sources);
    }
  }, [movie, checkAllSourcesLatency, getPlaySources]);

  const fetchMovieDetails = useCallback(async () => {
    if (movie) return;
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_ENDPOINT}?ac=list&ids=${id}`);
      if (response.data.list && response.data.list.length > 0) {
        setMovie(response.data.list[0]);
      } else {
        setError('影片详情未找到。');
      }
    } catch (error) {
      console.error('获取影片详情出错:', error);
      setError('获取影片详情时发生错误，请稍后再试。');
    } finally {
      setLoading(false);
    }
  }, [id, movie]);
  // useEffect(() => {
  //   // 初始化 vConsole
  //   const vConsole = new VConsole();
  //   return () => {
  //     // 组件卸载时销毁 vConsole
  //     vConsole.destroy();
  //   };
  // }, []);
  useEffect(() => {
    fetchMovieDetails();
  }, [fetchMovieDetails]);
  // const blobUrlRef = useRef(null);
  const processM3U8 = useCallback(async (playUrl) => {
    try {
      // 处理第一层 M3U8
      // const decodedUrl = decodeURIComponent(playUrl);
      // console.log('第一层解码后的URL:', decodedUrl);

      // // 检测是否为移动设备
      // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // if (isMobile) {
        return playUrl;
      // }
      // const response = await fetch(decodedUrl);
      // let content = await response.text();
      
      // // 从第一层 M3U8 中提取第二层 M3U8 的 URL
      // const first_lines = content.split('\n');
      // const secondLayerUrl = first_lines.find(line => 
      //   !line.startsWith('#') && line.trim() !== ''
      // );
      
      // if (!secondLayerUrl) {
      //   throw new Error('无法找到有效的视频地址');
      // }

      // // 处理第二层 M3U8
      // const urlObj = new URL(decodedUrl);
      // const baseUrl = `${urlObj.protocol}//${urlObj.hostname}/`;;
      // const cleanPath = secondLayerUrl.replace(/^\/+/, '').replace(/\/+/g, '/');
      // const secondUrl = secondLayerUrl.startsWith('http') 
      //   ? secondLayerUrl 
      //   : baseUrl + cleanPath;
      
      // // console.log('第二层M3U8 URL:', secondUrl);
      
      // const secondResponse = await fetch(secondUrl);
      // const secondContent = await secondResponse.text();

      // // 移除广告片段
      // const lines = secondContent.split('\n');
      // let processedLines = [];
      // let isAdSegment = false;
      // let hasEndList = false;
      // for (let i = 0; i < lines.length; i++) {
      //   const line = lines[i];

      //   // 检测广告片段的开始和结束
      //   if (line.includes('#EXT-X-DISCONTINUITY')) {
      //     isAdSegment = !isAdSegment;
      //     continue;
      //   }
        
      //   // 跳过广告片段
      //   if (isAdSegment) {
      //     continue;
      //   }
      //   // 检查是否有结束标记
      //   if (line.includes('#EXT-X-ENDLIST')) {
      //     hasEndList = true;
      //   }        
      //   // 处理正常的视频片段
      //   processedLines.push(line);
      // }
      // // 确保有结束标记
      // if (!hasEndList) {
      //   processedLines.push('#EXT-X-ENDLIST');
      // }
      
      // const processedContent = processedLines.join('\n');
      // // console.log('处理后的M3U8内容:', processedContent);

      // // 清理旧的 blob URL
      // if (blobUrlRef.current) {
      //   URL.revokeObjectURL(blobUrlRef.current);
      // }

      // const blob = new Blob([processedContent], { 
      //   type: 'application/x-mpegURL'
      // });
      // const blobUrl = URL.createObjectURL(blob);
      // blobUrlRef.current = blobUrl;
      // return URL.createObjectURL(blob);
    } catch (error) {
      console.error('处理m3u8文件出错:', error);
      return playUrl; // 出错时返回解码后的原始URL
    }
  }, []);
  
  const handleEpisodeClick = useCallback((playUrl) => {
    setIsVideoLoading(true);
    setCurrentEpisodeUrl(playUrl);
    currentEpisodeUrlRef.current = playUrl;
    processM3U8(playUrl).then(processedUrl => {
      setSelectedEpisode(processedUrl);
      setIsVideoLoading(false);
    });
  }, [processM3U8]);

  useEffect(() => {
    if (movie?.vod_play_url) {
      const savedProgress = localStorage.getItem(`play-progress-${movie.vod_id}`);
      if (savedProgress) {
        try {
          // 其次使用本地存储的播放进度
          const { source, episode } = JSON.parse(savedProgress);
          setCurrentSource(source);
          if (movie.vod_play_url.includes(episode)) {
            handleEpisodeClick(episode);
          }
        } catch (e) {
          console.error('解析播放进度失败:', e);
          const sources = getPlaySources(movie);
          if (sources.length > 0) {
            setCurrentSource(sources[0].name);
            const firstEpisode = sources[0].episodes[0]?.url;
            if (firstEpisode) {
              handleEpisodeClick(firstEpisode);
            }
          }
        }
      } else {
        // 最后使用第一集
        const sources = getPlaySources(movie);
        if (sources.length > 0) {
          setCurrentSource(sources[0].name);
          const firstEpisode = sources[0].episodes[0]?.url;
          if (firstEpisode) {
            handleEpisodeClick(firstEpisode);
          }
        }
      }
    }
  }, [movie, handleEpisodeClick, searchParams,getPlaySources]);
  
  useEffect(() => {
    if (selectedEpisode && playerContainerRef.current) {
      // 销毁旧的播放器实例
      if (dPlayerRef.current) {
        dPlayerRef.current.destroy();
      }
      // 确保 DOM 已经准备好
      const dp = new DPlayer({
        container: playerContainerRef.current,
        screenshot: true,
        contextmenu: [
          {
            text: '画中画',
            click: (player) => {
              if (document.pictureInPictureElement) {
                document.exitPictureInPicture();
              } else if (document.pictureInPictureEnabled) {
                player.video.requestPictureInPicture();
              }
            }
          }
        ],
        video: {
          url: selectedEpisode,
          pic: movie?.vod_pic,
          type: 'customHls',
          customType: {
            customHls: function (video) {
              // 仅在链接是 m3u8 格式时使用 HLS
              const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
              video.src = selectedEpisode;
              if (isMobile && video.canPlayType('application/vnd.apple.mpegurl')) {
                // iOS 设备使用原生 HLS
                video.src = selectedEpisode;
              } else if (Hls.isSupported()) {
                // 其他设备使用 hls.js
                const hls = new Hls({
                  debug: false,
                  enableWorker: true,
                  lowLatencyMode: true
                });
                hls.loadSource(video.src);
                hls.attachMedia(video);
                hls.on(Hls.Events.ERROR, function (event, data) {
                  console.log('HLS 错误:', data);
                });
              } else {
                console.log('当前设备不支持 HLS 播放');
              }
            }
          }
        },
        preload: 'auto',
        mutex: true,
        autoplay: true
      });

      // 保存实例引用
      dPlayerRef.current = dp;

      // 错误处理
      dp.on('error', function (err) {
        console.log('播放器错误:', err);
      });
      // console.log('HLS 插件:', dp.plugins.hls);
  

      const controlBar = dp.container.querySelector('.dplayer-icons-right');
      if (controlBar) {
        const pipButton = document.createElement('div');
        pipButton.className = 'dplayer-setting';
        pipButton.innerHTML = `
          <button class="dplayer-icon dplayer-pip-icon">
            <svg viewBox="0 0 24 24" width="22" height="22">
              <path fill="#fff" d="M19 7h-8v6h8V7zm2-4H3C2 3 1 4 1 5v14c0 1 1 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2zm0 16H3V5h18v14z"/>
            </svg>
          </button>
        `;
        pipButton.onclick = () => {
          if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
          } else if (document.pictureInPictureEnabled) {
            dp.video.requestPictureInPicture();
          }
        };
        controlBar.insertBefore(pipButton,controlBar.firstChild);
      }


      // 加载完成后设置上次播放时间
      dPlayerRef.current.on('loadedmetadata', () => {
        const savedProgress = localStorage.getItem(`play-progress-${movie.vod_id}`);
        if (savedProgress) {
          try {
            const { source, episode, time } = JSON.parse(savedProgress);
            if (source === currentSource && 
                episode === currentEpisodeUrlRef.current && 
                time > 0 && 
                time < dPlayerRef.current.video.duration) {
              dPlayerRef.current.seek(time);
            }
          } catch (e) {
            console.error('解析播放进度失败:', e);
          }
        }

        // 读取并设置全局播放速度
        const savedPlaybackRate = localStorage.getItem('user-playback-rate');
        if (savedPlaybackRate) {
          dPlayerRef.current.speed(parseFloat(savedPlaybackRate));
        }

      });

      // 添加 loadeddata 事件监听
      dPlayerRef.current.on('loadeddata', () => {
        setIsVideoLoading(false);
      });
  
      // 更新播放进度
      dPlayerRef.current.on('timeupdate', () => {
        const currentTime = dPlayerRef.current.video.currentTime;
        if (currentTime > 0) {
          localStorage.setItem(
            `play-progress-${movie.vod_id}`,
            JSON.stringify({
              source: currentSource,
              episode: currentEpisodeUrlRef.current,
              time: currentTime
            })
          );
        }
      });

      // 监听倍速变化，保存为全局设置
      dPlayerRef.current.on('ratechange', () => {
        const currentRate = dPlayerRef.current.video.playbackRate;
        localStorage.setItem('user-playback-rate', currentRate.toString());
      });
    }
  
    return () => {
      if (dPlayerRef.current) {
        dPlayerRef.current.destroy();
      }
    };
  }, [selectedEpisode, movie, currentSource]);
  
  // const handleShare = () => {
  //   const shareUrl = `${window.location.origin}/detail/${movie.vod_id}`;
  //   navigator.clipboard.writeText(shareUrl).then(() => {
  //     alert('分享信息复制成功，赶紧去分享吧！');
  //   }).catch((err) => {
  //     console.error('复制失败: ', err);
  //     alert('复制链接失败，请重试。');
  //   });
  // };
  
  if (loading) return <p className="loading">加载中...</p>;
  if (error) return <p className="error">{error}</p>;
  if (!movie) return null;
  
  return (
    <div className="movie-detail">
      {movie && (
        <Helmet>
          <title>{movie.vod_name} - 在线观看</title>
          <meta name="description" content={`${movie.vod_name}在线观看，主演：${movie.vod_actor}，导演：${movie.vod_director}，${movie.vod_content?.slice(0, 100)}...`} />
          <meta name="keywords" content={`${movie.vod_name},${movie.type_name},${movie.vod_actor},在线观看,电影,电视剧`} />
          <meta property="og:title" content={`${movie.vod_name} - 在线观看`} />
          <meta property="og:description" content={movie.vod_content} />
          <meta property="og:image" content={movie.vod_pic} />
          <meta property="og:url" content={`https://www.91aitv.com/detail/${movie.vod_id}`} />
          <meta property="og:type" content="video.movie" />
          <link rel="canonical" href={`https://www.91aitv.com/detail/${movie.vod_id}`} />
        </Helmet>
      )}
      <h2>{movie.vod_name}</h2>
      <div className="player-wrapper">
        {isVideoLoading && (
          <div className="video-placeholder">
            <div className="loading-spinner">加载中...</div>
          </div>
        )}
        {selectedEpisode && (
          <div ref={playerContainerRef} style={{ 
            opacity: isVideoLoading ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out'
          }} />
        )}
      </div>
      
      {movie.vod_play_url && (
        <div className="episodes-container">
          <div className="source-tabs">
            {getPlaySources(movie).map((source, index) => (
              <button
                key={index}
                onClick={() => {
                  setCurrentSource(source.name);
                  // 自动播放新源的第一集
                  const firstEpisode = source.episodes[0]?.url;
                  if (firstEpisode) {
                    handleEpisodeClick(firstEpisode);
                  }
                }}
                className={currentSource === source.name ? 'active' : ''}
                style={{ position: 'relative' }}
              >
                {source.name}
                {sourcePings[source.name] !== undefined && (
                  <span 
                    style={{
                      backgroundColor: sourcePings[source.name] === -1 ? '#ff4d4f' : 
                                    sourcePings[source.name] > 1000 ? '#ffa940' : '#52c41a',
                      
                    }}
                  >
                    {sourcePings[source.name] === -1 ? '超时' : 
                    `${sourcePings[source.name]}ms`}
                  </span>
                )}
              </button>
            ))}
          </div>
          {/* <h3>剧集列表</h3> */}
          <div className="episodes">
          {getPlaySources(movie).find(s => s.name === currentSource)?.episodes.map((episode, index) => (
          <button 
            key={index} 
            onClick={() => handleEpisodeClick(episode.url)}
            className={episode.url === currentEpisodeUrl ? 'active' : ''}
          >
        {episode.epName}
      </button>
    ))}
          </div>
        </div>
      )}

    <div className="movie-info">
        <div className="movie-poster">
          <img src={movie.vod_pic} alt={movie.vod_name} />
        </div>
        <div className="movie-meta">
          <p><strong>类型:</strong> {movie.type_name}</p>
          <p><strong>导演:</strong> {movie.vod_director}</p>
          <p><strong>主演:</strong> {movie.vod_actor}</p>
          <p><strong>年份:</strong> {movie.vod_year}</p>
          <p><strong>地区:</strong> {movie.vod_area}</p>
          <p><strong>简介:</strong> {movie.vod_content}</p>
          {/* <button onClick={handleShare} className="share-button">
            <span className="share-icon">&#x1F4CB;</span> 分享
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Detail;