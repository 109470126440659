import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINT, DEFAULT_PAGE_SIZE } from '../config/api';
import { Helmet } from 'react-helmet';
import { typeData } from '../data/typeList';

function List() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);
  
  const type = searchParams.get('type') || '';
  const keyword = searchParams.get('keyword') || '';
  const page = parseInt(searchParams.get('page') || '1', 10);
  
  const getCategoryName = (typeId) => {
    const category = typeData.find(item => item.type_id.toString() === typeId);
    return category ? category.type_name : '全部';
  };

    // 将 fetchList 移出 useCallback，直接在 useEffect 中定义
    useEffect(() => {
    const fetchList = async () => {
        setLoading(true);
        setError('');
        try {
        const response = await axios.get(
            `${API_ENDPOINT}?ac=list&wd=${encodeURIComponent(keyword)}&t=${type}&pg=${page}&pagesize=${pageSize}`
        );
        
        if (response.data.list && response.data.list.length > 0) {
            setTotalPages(parseInt(response.data.pagecount));
            setCurrentPage(parseInt(response.data.page, 10));
            
            // 批量获取所有电影的详情
            // const movieIds = response.data.list.map(movie => movie.vod_id).join(',');
            // try {
            // const detailResponse = await axios.post(
            //     `${API_ENDPOINT}?ac=detail&ids=${movieIds}`
            // );
            // if (detailResponse.data.list) {
            //     setSearchResults(detailResponse.data.list);
            // } else {
                setSearchResults(response.data.list);
            // }
            // } catch (error) {
            // console.error('获取详情出错:', error);
            // setSearchResults(response.data.list);
            // }
        } else {
            setError('未找到相关影视资源。');
        }
        } catch (error) {
        console.error('搜索出错:', error);
        setError('搜索时发生错误，请稍后再试。');
        } finally {
        setLoading(false);
        }
    };

    fetchList();
    window.scrollTo(0, 0);
    }, [keyword, type, page, pageSize]); // 直接依赖于这些值，而不是依赖于回调函数
  
  const handlePageChange = (newPage) => {
    navigate(`/list?type=${type}&keyword=${keyword}&page=${newPage}`);
  };
  
  const handleMovieClick = (movie) => {
    navigate(`/detail/${movie.vod_id}`, { state: { movie } });
  };
  
  return (
    <div className="list-page">
      <Helmet>
      <title>{`${getCategoryName(type)}${keyword ? ` - ${keyword}` : ''} - 影视列表`}</title>
        <meta name="description" content={`${getCategoryName(type)}在线观看，${keyword ? `${keyword}相关影片，` : ''}海量影视资源，提供最新电影、电视剧、动漫在线观看`} />
        <meta name="keywords" content={`${getCategoryName(type)},${keyword || '最新'},在线观看,免费观看`} />
        <meta property="og:title" content={`${getCategoryName(type)}${keyword ? ` - ${keyword}` : ''} - 影视列表`} />
        <meta property="og:description" content={`${getCategoryName(type)}在线观看，${keyword ? `${keyword}相关影片，` : ''}海量影视资源`} />
      </Helmet>
      {loading && <p className="loading">加载中...</p>}
      {error && <p className="error">{error}</p>}
      
      <div className="search-results">
        {searchResults.map((movie) => (
          <div 
            key={movie.vod_id} 
            className="movie-item" 
            onClick={() => handleMovieClick(movie)}
          >
            <img src={movie.vod_pic} alt={movie.vod_name} />
            <h3>{movie.vod_name}</h3>
          </div>
        ))}
      </div>
      
      {totalPages > 1 && (
        <div className="pagination">
          <button 
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            首页
          </button>
          <button 
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            上一页
          </button>
          <span>{currentPage} / {totalPages}</span>
          <button 
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            下一页
          </button>
          <button 
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            尾页
          </button>
        </div>
      )}
    </div>
  );
}

export default List;