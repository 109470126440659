import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { typeList } from '../data/typeList';
import { SITE_TITLE } from '../config/api';
function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleTypeClick = (type) => {
    navigate(`/list?type=${type}`);
    setIsMenuOpen(false);
  };

  const handleSearchClick = () => {
    navigate(`/list?keyword=${encodeURIComponent(searchTerm)}`);
    setIsMenuOpen(false);
  };

  return (
    <header className="App-header">
      <div className="header-top">
        <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <span className="hamburger"></span>
        </div>
        <h1 data-relingo-block="true">{SITE_TITLE}</h1>
        <div className="placeholder"></div>
      </div>
      <div className={`mobile-nav ${isMenuOpen ? 'open' : ''}`}>
        <div className="logonavbar">
          <div className="nav-item">
            <div className="nav-link">
              <button
                className="nav-button"
                onClick={() => {
                  navigate('/');
                  setIsMenuOpen(false);
                }}
              >
                首页
              </button>
            </div>
          </div>
          {typeList.map(type => (
            <div className="nav-item" key={type.id}>
              <div className="nav-link">
                <button
                  className="nav-button"
                  onClick={() => handleTypeClick(type.id)}
                >
                  {type.name}
                </button>
                {type.subTypes && <img src="/xiala.png" alt="展开" />}
              </div>
              {type.subTypes && (
                <div className="nav-drop">
                  {type.subTypes.map(subType => (
                    <div className="dropitem" key={subType.id}>
                      <button
                        className="nav-button dropdown-item"
                        onClick={() => handleTypeClick(subType.id)}
                      >
                        {subType.name}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="输入影视名称..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchClick();
            }
          }}
        />
        <button onClick={handleSearchClick}>搜索</button>
      </div>
    </header>
  );
}

export default Header;