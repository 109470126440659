import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINT, DEFAULT_PAGE_SIZE } from '../config/api';
import { Helmet } from 'react-helmet';
function Home() {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const navigate = useNavigate();

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
    localStorage.setItem('hasSeenAnnouncement', 'true');
  };
  
  useEffect(() => {
    // 检查是否需要显示公告
    const hasSeenAnnouncement = localStorage.getItem('hasSeenAnnouncement');
    if (!hasSeenAnnouncement) {
      setShowAnnouncement(true);
    }

    const fetchDefaultMovies = async () => {
      setLoading(true);
      setError('');
      try {
        // 获取默认影片列表，不带任何搜索条件
        const response = await axios.get(
          `${API_ENDPOINT}?ac=list&pg=1&pagesize=${DEFAULT_PAGE_SIZE}`
        );
        
        if (response.data.list && response.data.list.length > 0) {
          // 批量获取所有电影的详情
          // const movieIds = response.data.list.map(movie => movie.vod_id).join(',');
          try {
            // const detailResponse = await axios.post(
            //   `${API_ENDPOINT}?ac=detail&ids=${movieIds}`
            // );
            // if (detailResponse.data.list) {
            //   setMovies(detailResponse.data.list);
            // } else {
              setMovies(response.data.list);
            // }
          } catch (error) {
            console.error('获取详情出错:', error);
            setMovies(response.data.list);
          }
        } else {
          setError('未找到影视资源。');
        }
      } catch (error) {
        console.error('加载数据出错:', error);
        setError('加载数据时发生错误，请稍后再试。');
      } finally {
        setLoading(false);
      }
    };
    
    fetchDefaultMovies();
  }, []); // 空依赖数组，只在组件挂载时执行一次
  
  
  const handleMovieClick = (movie) => {
    navigate(`/detail/${movie.vod_id}`, { state: { movie } });
  };
  
  return (
    <div className="home-page">
      <Helmet>
        <title>十分阳光影院-永久免费的福利超清影视站</title>
        <meta name="keywords" content="Netflix,VIP电影,热播电视剧,在线观看,蓝光电影,搞笑视频,美剧,韩剧,超前点播,免费视频,在线视频,4K电影,奈飞,超清电影,电影下载,娱乐资讯" />
        <meta name="description" content="十分阳光影院(91aitv.com)是一家免费全球影视资源在线观看的平台,拥有海量、优质、超清蓝光电影和全球的电视剧,高画质在线动漫。专业全网收集最新,最好看的电视剧、高清电影、经典动漫、综艺娱乐节目,十分阳光影院以丰富的内容、极致的观看体验、便捷的高速播放、24小时多平台无缝应用体验以及快捷!" />
      </Helmet>
      {showAnnouncement && (
        <div className="announcement-overlay">
          <div className="announcement-modal">
            <h2>公告内容</h2>
            <div className="announcement-content">
              <p>◆ 官方公告</p>
              <p>一、记住官网https://91aitv.com</p>
              <p>二、无法使用请切换播放源</p>
              <p>三、关注公众号"十分阳光影院"不迷路</p>
            </div>
            <button onClick={handleCloseAnnouncement} className="announcement-btn">
              好的，我记住啦
            </button>
          </div>
        </div>
      )}


      {loading && <p className="loading">加载中...</p>}
      {error && <p className="error">{error}</p>}
      
      <div className="search-results">
        {movies.map((movie) => (
          <div 
            key={movie.vod_id} 
            className="movie-item" 
            onClick={() => handleMovieClick(movie)}
          >
            <img src={movie.vod_pic} alt={movie.vod_name} />
            <h3>{movie.vod_name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;