import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import List from './pages/List';
import Detail from './pages/Detail';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/list" element={<List />} />
            <Route path="/detail/:id" element={<Detail />} />
          </Routes>
        </main>
        <footer className="App-footer">
          <div className="footer-links">
            <a href="https://編rr甦.greendh3.com/4烜" className="footer-link" target="_blank" rel="noopener noreferrer">绿色小导航</a>
            <a href="https://lrc.bluedh.cloud/b992" className="footer-link" target="_blank" rel="noopener noreferrer">蓝色导航</a>
            <a href="https://i9.landh.page/xaki" className="footer-link" target="_blank" rel="noopener noreferrer">蓝导航</a>
          </div>      
        </footer>
      </div>
    </Router>
  );
}

export default App;